import { DataProvider, GlobalActionsProvider } from '@plasmicapp/host';
import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useScrollStore } from 'hooks/stores/useScrollStore';
import { scrollToSection } from 'utils/common';

export const ScrollGlobalContext = observer(
  ({ children }: React.PropsWithChildren) => {
    const { isAboveThreshold } = useScrollStore();

    const scrollToSpotlight = () => {
      scrollToSection('#spotlight-top'); // set in plasmic
    };

    return (
      <GlobalActionsProvider
        contextName="Scroll"
        actions={{
          scrollToSection: scrollToSpotlight,
        }}
      >
        <DataProvider
          name="scroll"
          data={{
            isAboveThreshold,
          }}
        >
          {children}
        </DataProvider>
      </GlobalActionsProvider>
    );
  }
);
