import { DataProvider, GlobalActionsProvider } from '@plasmicapp/host';
import { useUIStore } from 'hooks/stores/useUIStore';
import { useUserStore } from 'hooks/stores/useUserStore';
import { useSubscriberStatus } from 'hooks/useSubscriberStatus';
import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';

export const UserGlobalContext = observer(
  ({ children }: React.PropsWithChildren) => {
    const router = useRouter()
    const {
      wishListPrizes,
      eligibleForIntroOffer,
      eligibleForUpgrade,
      currentUser,
      subscriptions,
      upcomingDrawTickets,
      initialized,
      impactShareOptions,
      setLeaderboardPicture,
      fetchWishListPrizes,
      toggleWishlistPrize,
      submitHelpQuestion,
      submitAccountDetails,
      logout,
    } = useUserStore();
    const { subscriberStatus, isPremiumSubscriber } =
      useSubscriberStatus(currentUser);

    const { getAccountLink } = useUIStore()

    useEffect(() => {
      if (
        initialized && 
        router.isReady &&
        getAccountLink().includes(router.asPath) &&
        !currentUser
      ) {
        router.replace('/');
      }
    }, [router.isReady, currentUser, initialized]);

    return (
      <GlobalActionsProvider
        contextName="UserGlobalContext"
        actions={{
          setLeaderboardPicture,
          fetchWishListPrizes,
          toggleWishlistPrize,
          submitHelpQuestion,
          submitAccountDetails,
          logout,
        }}
      >
        <DataProvider
          name="user"
          data={{
            currentUser,
            impactShareOptions,
            subscription: subscriptions?.lotterySubscriptions
              ? subscriptions.lotterySubscriptions[0]
              : null,
            upcomingDrawTickets,
            eligibleForIntroOffer,
            eligibleForUpgrade,
            subscriberStatus,
            isPremiumSubscriber,
            wishListPrizes,
          }}
        >
          {children}
        </DataProvider>
      </GlobalActionsProvider>
    );
  }
);
