import { DataProvider, GlobalActionsProvider } from '@plasmicapp/host';
import { useABTestStore } from 'hooks/stores/useABTestStore';
import { useUIStore } from 'hooks/stores/useUIStore';
import { observer } from 'mobx-react-lite';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import {
  MultistepStandardPaths,
  MultistepTestPaths,
} from 'types/ui/EnterFlowProgress';
import {
  calculateProgressInfo,
  getMultistepPaths,
  getMultistepTestGroup,
} from 'utils/ab-test/multistep/MultistepV4';


export const UIGlobalContext = observer(
  ({ children }: React.PropsWithChildren) => {
    const router = useRouter();
    const { abTests, updateABTestValuesFromLocalStorage } = useABTestStore();

    const {
      enterFlowProgress,
      showVideoOverlay,
      setEnterFlowProgress,
      setShowVideoOverlay,
      setVideoOverlayLink,
      setVideoOverlayType,
      removeVideoOverlayLink,
    } = useUIStore();

    useEffect(() => {
      const currentPath = router.asPath.split('?')[0];

      if (
        ![...MultistepStandardPaths, ...MultistepTestPaths].includes(
          currentPath
        )
      ) {
        return;
      }

      const testGroup = getMultistepTestGroup();
      updateABTestValuesFromLocalStorage()

      const pathsArray = getMultistepPaths(testGroup);


      const { currentStep, totalSteps } = calculateProgressInfo(
        currentPath,
        pathsArray
      );

      setEnterFlowProgress({ currentStep, totalSteps });
    }, [router.asPath]);

    return (
      <GlobalActionsProvider
        contextName="UIGlobalContext"
        actions={{
          setShowVideoOverlay,
          setVideoOverlayLink,
          removeVideoOverlayLink,
          setVideoOverlayType,
        }}
      >
        <DataProvider
          name="ui"
          data={{
            enterFlowProgress,
            showVideoOverlay,
            abTests,
          }}
        >
          {children}
        </DataProvider>
      </GlobalActionsProvider>
    );
  }
);
