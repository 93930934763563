import { MultistepStandardPaths, MultistepTestPaths } from 'types/ui/EnterFlowProgress';
import { doABTestWithDistribution } from 'utils/analytics/abtest';
import { getTestGroupFromQueryOrStorage } from '../shared/getTestGroupFromQueryOrStorage';


export type MultiStepTestGroup = 'CONTROL' | 'SKIPNUMBERS' | 'HYBRID';
export const validMultistepTestGroups: MultiStepTestGroup[] = [
  'CONTROL',
  'SKIPNUMBERS',
  'HYBRID',
];

export const MULTISTEP_TEST_NAME = 'MultiStepEnterFlow_V4';

export const getMultistepTestGroup = (): MultiStepTestGroup => {

  // Try to get the test group from the query or localStorage
  const testGroupFromQueryOrStorage =
    getTestGroupFromQueryOrStorage<MultiStepTestGroup>(
      MULTISTEP_TEST_NAME,
      validMultistepTestGroups
    );

  if (testGroupFromQueryOrStorage) {
    return testGroupFromQueryOrStorage;
  }

  // Fallback to A/B test logic if no test group is found
  const testGroup = doABTestWithDistribution(MULTISTEP_TEST_NAME, [
    { choice: 'CONTROL', weight: 1 },
    { choice: 'SKIPNUMBERS', weight: 0 },
    { choice: 'HYBRID', weight: 1 },
  ]) as MultiStepTestGroup;

  return testGroup;
};



export const getMultistepPaths = (testGroup: MultiStepTestGroup) => {
    return testGroup !== 'CONTROL' ? MultistepTestPaths : MultistepStandardPaths;
}

export const calculateProgressInfo = (
  currentPath: string,
  paths: typeof MultistepStandardPaths | typeof MultistepTestPaths
) => {
  const currentStepIndex = paths.indexOf(currentPath);
  const currentStep = currentStepIndex + 1;
  const totalSteps = paths.length;;
  return { currentStep, totalSteps };
};